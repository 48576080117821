<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Mis Informes Generales</h4>
            <div class="small text-muted">Administración de todos mis informes generales</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Informe">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="12">
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>        
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"    
                    v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(date)="data">
                  {{ moment(data.item.date).format('DD MMMM YYYY') }}
                </template>
              
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >80" :title="data.item.observations">
                      {{data.item.observations.substr(0, 80)}}
                    </div>
                    <div v-else>
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">  
                    <b-dropdown-item @click="irDetail(data.item)">
                      <b-icon icon="card-text"></b-icon> Detalle
                    </b-dropdown-item>       
                    <b-dropdown-item @click="sendPrinterOrder(data.item)">
                      <b-icon icon="printer"></b-icon> Imprimir
                    </b-dropdown-item>    
                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)" v-if="access.elements.editReport">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterReport()" />
            </nav>    
          </b-col>          
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop>
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea                
                v-model="crud.form.observations"
                placeholder="Ingresar las observaciones..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>         
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'  
  import Storage from '@/handler/storageSession'
  import Printer from '@/components/inc/printer/printer'
  
  export default {   
    components: {
      Printer,       
    }, 
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_GENERAL,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudReportSeller',
          elements: {
            editReport: true,                              
          }
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: "align-middle"},
            {key: 'date', label: 'Fecha', class: "align-middle"},       
            {key: 'observations', label: 'Observaciones', class: "align-middle"},            
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,               
        },
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',                        
            sellers_id: 0,
            read: false,
          },
          print: {
            reference: 'reports-general',
            id: 0,
            key: 0,
          }           
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        filter: {
          date_start: '',
          date_end: '',          
          sellers: [],
        },     

      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.editReport = Helper.hasAccessElement(this.access, 'edit_report')      
      /* Fin configuracion */
    },  
    mounted() {      
      this.filterLoad()
      this.filterReport()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''        
        this.crud.form.sellers_id = Helper.getSeller().id        
        this.crud.form.read = false
              
        this.modal.form.title = "Nuevo Reporte"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations        
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.read = item.read
                
        this.modal.form.title = "Editar Reporte"
        this.modal.form.active = true
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Reporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            if (this.crud.form.id) {
              var result = serviceAPI.editar(this.crud.form);
            } else {
              var result = serviceAPI.agregar(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterReport()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },
      
      filterLoad() {        
        this.filter.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage() 
      },            
      filterReport(){
        this.table.isBusy = true 
        
        this.filter.sellers = []
        this.filter.sellers.push({
          code: Helper.getSeller().id,
          label: Helper.getSeller().name,
        })   

        var result = serviceAPI.filtrarReport(this.filter, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data                    

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }             

          this.table.isBusy = false 
        })
        .catch(error => {
          this.table.isBusy = false 
          this.$awn.alert(Error.showError(error))
        });          
      },   

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_report_seller_general', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_report_seller_general')) {
          this.filter = JSON.parse(Storage.getValue('filter_report_seller_general'))
        }         
      },

      irDetail(item){
        this.saveFilterStorage()
        this.$router.push({ name: 'ReportsGeneralStaffDetailReport', params: {reportID: item.id} })
      },
      sendPrinterOrder(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      }
    }    
  }
</script>
<style>
  .report-general-crud-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>